@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .banner-text {
    font-size: 3.50rem;
    line-height: 1.3;
  }

  .backgroundColor-tickmark {
    background-color: rgba(189, 132, 114, 0.3);
  }

  .left-custom {
    left: 29%;
  }

  .max-w-9xl {
    max-width: 90rem;
  }

  .max-w-10xl {
    max-width: 96rem;
  }

  .px-6-custom {
    padding-left: 12.5rem;
    padding-right: 1.5rem;
  }
  .height-custom {
    height: calc(100vh - 130px);
  }
  .height-custom-picture {
    height: calc(100vh - 1px);
  }

  .height-custom-notifications {
    height: calc(100vh - 220px);
  }

  .custom-border-radius {
    border-radius: 0px 10px 10px 10px;
  }
  .owner-border-radius {
    border-radius: 10px 0px 10px 10px;
  }
  .truncate-custom {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-scrollbar {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }
  .custom-scrollbar-x {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-x: scroll;
  }

  .custom-scrollbar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .custom-focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border: none !important;
  }
  .spoiler button {
    visibility: hidden;
  }

  .spoiler:hover button {
    visibility: visible;
  }
  .customdate input[type="date"]::-webkit-inner-spin-button,
  .customdate input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  .banner-background {
    background-color: linear-gradient(
      88.39deg,
      #162a43 -4.47%,
      rgba(22, 42, 67, 0.78) 31.2%,
      rgba(255, 255, 255, 0) 99.42%
    );
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
